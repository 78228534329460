@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Satoshi';
    src: local('SatoshiRegular'),
    url('./fonts/Satoshi-Regular.woff') format('woff');
    font-weight: 400; /* Regular */
}

@font-face {
    font-family: 'Satoshi';
    src: local('SatoshiLight'),
    url('./fonts/Satoshi-Light.woff') format('woff');
    font-weight: 300; /* Light */
}

@font-face {
    font-family: 'Satoshi';
    src: local('SatoshiMedium'),
    url('./fonts/Satoshi-Medium.woff') format('woff');
    font-weight: 500; /* Medium */
}

@font-face {
    font-family: 'Satoshi';
    src: local('SatoshiBold'),
    url('./fonts/Satoshi-Bold.woff') format('woff');
    font-weight: 700; /* Bold */
}

@font-face {
    font-family: 'Satoshi';
    src: local('SatoshiBlack'),
    url('./fonts/Satoshi-Black.woff') format('woff');
    font-weight: 800; /* Black */
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0) rotate(0deg);
    }
    50% {
        transform: translateY(-12px) rotate(-3deg);
    }
}

@keyframes rotateAndFloat {
    0%,
    100% {
        transform: rotate(-3deg) translateY(0);
    }
    50% {
        transform: rotate(3deg) translateY(-6px);
    }
}

.floating {
    animation: float 8s ease-in-out infinite;
}

.rotatingFloating {
    animation: rotateAndFloat 12s ease-in-out infinite;
}

root {
    background-color: white;
}
