.modal-new-open {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050 !important;
  top: 0;
  right: 0;
  bottom: 0;
  /*overflow: auto;*/
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 150ms ease;
  outline: none !important;
  box-sizing: border-box;
  overflow: hidden;
}

.modal-content {
  background-color: white;
  border: 2px solid #00000020;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  /*overflow: scroll;*/
  box-shadow: 0 12px 20px #00000020;
}

.modal-content-xs {
  min-width: min(300px, 95vw);
  max-width: min(300px, 95vw);
}

.modal-content-sm {
  min-width: min(383px, 95vw);
  max-width: min(383px, 95vw);
}

.modal-content-md {
  min-width: min(500px, 95vw);
  max-width: min(500px, 95vw);
}

.modal-content-lg {
  min-width: min(800px, 95vw);
  max-width: min(800px, 95vw);
}

.modal-content-xl {
  min-width: min(1140px, 95vw);
  max-width: min(1140px, 95vw);
}

.modal-content-full {
  width: 100vw;
  height: 100vh;
}

.modal-content-full-mobile {
  width: 100vw;
  min-height: 100vh;
}

.close-modal-icon {
  transition: transform 300ms ease-in-out;
  transform: scale(0.8);
}

.close-modal-icon:hover {
  transform: rotate(-90deg) scale(1);
}
